






import NavigationArea from "@/chipply/NavigationArea";
import Vue from "vue";
import Component from "vue-class-component";
import NavigationHost from "../navigation/NavigationHost.vue";
import OrderStoresGrid from "../events/OrderStoresGrid.vue";
import ProductsSpreadsheetView from "../events/ProductsSpreadsheetView.vue";
import NavigationSide from "../navigation/NavigationSide.vue";

// THIS EXISTS ONLY FOR OUR FRANKENSTEIN Vue/WebForm pages, to correct styling problems
@Component({
    components: { NavigationSide },
    props: {
        menuData: Object,
        navigationType: Number,
    },
})
export default class NavigationSidePage extends Vue {
    public name = "NavigationSidePage";
    public init = true;
    public data() {
        return {};
    }
}
